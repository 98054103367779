export const shell = 'shell';
export const python = 'python';

export const snippets = (showToken, token) => {
  return {
    shell: `echo 'export UPGINI_API_KEY="${showToken ? token : token.replaceAll(/./g, '*')}"' \\\n>> $HOME/.bashrc`,
    python: `from upgini import FeaturesEnricher, SearchKey

enricher = FeaturesEnricher(
  search_keys = {
    "key_date":SearchKey.DATE,
    "key_datetime": SearchKey.DATETIME,
    "key_phone": SearchKey.PHONE,
    "key_email": SearchKey.EMAIL,
    "key_hashed_email": SearchKey.HEM,
    "key_ip_address": SearchKey.IP
  },
  keep_input = True,
  api_key = "${showToken ? token : token.replaceAll(/./g, '*')}"
)`
  }
};

export const getCode = (language, showToken, token) => token ? snippets(showToken, token)[language] : '';
