import React from "react";
import './LogoutPage.scss';

const LogoutPage = () => {

    let params = new URLSearchParams(document.location.search);
    const noRedirect = params.get('noredirect');
    //const errCode = params.get('errcode');
    if (noRedirect!=='true'){
        setTimeout(() => window.location.replace('/login'), 2000);
    }

    return (
        <div className="logout-page-container">
            <p>You have logged out</p>
        </div>
    );
}

export default LogoutPage;