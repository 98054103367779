import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import jwt_decode from "jwt-decode";
import queryString from 'query-string';
import React from "react";
import { Spinner } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { showToast } from "./commons";
// import { trackRegisteredUser } from './trackLead';


const REFRESH_TOKEN_KEY = 'refreshToken';
const ACCESS_TOKEN_KEY = 'accessToken';

// config axios 
axios.defaults.baseURL = window.Config?.api.root;
axios.defaults.paramsSerializer = params => queryString.stringify(params);
axios.defaults.timeout = 10000;
axios.defaults.headers = {
  'Accept': 'application/json, text/plain',
  'Content-Type': 'application/json; charset=UTF-8'
};

export const getStoredRefershToken = () =>
  localStorage.getItem(REFRESH_TOKEN_KEY) || '';

export const storeRefershToken = (refreshToken) =>
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);

export const getStoredAccessToken = () =>
  localStorage.getItem(ACCESS_TOKEN_KEY) || '';

export const storeAccessToken = (accessToken) =>
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);

export const removeStoredAccessToken = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
};

export const removeStoredRefreshToken = () => {
  localStorage.removeItem(REFRESH_TOKEN_KEY);
}

// Return access token if valid and not expired else retrun false
const validateAccessToken = (accessToken) => {
  if (accessToken === 'undefined' || accessToken === 'false') return false;
  try {
    let expDate = new Date(jwt_decode(accessToken).exp * 1000);
    return new Date() < expDate ? accessToken : false;
  } catch {
    return false;
  }
};

// if authorized returns valid access token
export const authorized = async () => {
  let refreshToken = getStoredRefershToken();
  return refreshToken && (validateAccessToken(getStoredAccessToken()) || await refreshAccessToken(refreshToken));
}

// Use to get email from access Token only !
export const getEmailFromToken = (accessToken) => {
  try {
    return jwt_decode(accessToken).sub;
  } catch (err) {
    return '************?';
  }
}

// refresh access token
const refreshAccessToken = (refreshToken) => {
  return axios.post(
    window.Config.api.auth.refreshTokens,
    { refreshToken: refreshToken }
  ).then(response => {
    let newAccessToken = response?.data?.access_token;
    storeAccessToken(newAccessToken);
    return newAccessToken;
  }).catch(error => {
    let errCode = error.response?.status;
    removeStoredAccessToken();
    removeStoredRefreshToken();
    let message = errCode === 401 ?
      'It seems your api token has expired. You need to get the authorization link again.' :
      error.response?.data?.userMessage || 'Invalid authorization (code 7SHV2X)';
    showToast('⛔️', message, { type: 'error', onClose: () => window.location.replace(`/logout`) });
    return false;
  });
};

// Renew access token
export const renewRefreshToken = (refreshToken) => {
  return callSecuredAPI({
    method: 'POST',
    url: window.Config.api.auth.renewRefreshToken,
    data: { refreshToken: refreshToken }
  }).then(response => {
    let newAccessToken = response?.data?.access_token;
    let newRefreshToken = response?.data?.refresh_token;
    storeAccessToken(newAccessToken);
    storeRefershToken(newRefreshToken);
    return newRefreshToken;
  }).catch(error => {
    let errCode = error.response?.status;
    // removeStoredAccessToken();
    // removeStoredRefreshToken();
    let message = errCode === 401 ?
      'Problem renewing API token' :
      error.response?.data?.userMessage || 'Problem renewing API token (code AMA9JB)';
    showToast('⛔️', message, { type: 'error' });
    return false;
  });
};

// call API with authorization
export const callSecuredAPI = async (options) => {
  let accessToken = await authorized();
  let axiosOptions = options;
  if (accessToken) {
    axiosOptions.headers = { ...options.headers, Authorization: `Bearer ${accessToken}` };
    try {
      const { data, ...response } = await axios(axiosOptions);
      response.data = data;
      return response;
    } catch (error) {
      if (error.response && error.response.status === 429) {
        // Пауза в 3 секунды перед повторным вызовом функции
        console.warn("Retrying in 1 second")
        await new Promise(resolve => setTimeout(resolve, 1000));
        return callSecuredAPI(options);  // Рекурсивный вызов
      }
      console.error(`Error J9EZLT ${error}`);
      return false;
    }
  } else {
    throw new Error('Operation needs authorization');
  }
};

export const checkAuthorization = (codeFromEmail, ip) => {
  // remove previos tokens
  removeStoredAccessToken();
  removeStoredRefreshToken();
  var toastResult = 'none';
  var authToastParams = {
    toastId: 'authCheck',
    hideProgressBar: false,
    draggable: false,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    closeButton: false,
    autoClose: 10000,
    onClose: () => {
      switch (toastResult) {
        case 'none':
          window.location.replace('/login?err=authorizationTimeout');
          break;
        case 'success':
          window.location.replace('/');
          break;
        default:
          window.location.replace(`/login?err=invalidAuthorization&responseCode=${toastResult}`);
      }
    }
  };
  // Show `Auth check` message
  let authToastMessage = <span>
    &nbsp;
    <Spinner animation="border" variant="danger" size="sm" />
    &nbsp;
    Checking your authorization...
  </span>;
  toast(authToastMessage, authToastParams);
  // Send auth request
  return axios(window.Config.api.auth.getTokens, {
    params: {
      code: codeFromEmail,
      ip: ip
    }
  }).then((response) => {
    // put tokens into local storage
    localStorage.setItem('refreshToken', response.data.refresh_token);
    localStorage.setItem('accessToken', response.data.access_token);
    toastResult = 'success';
    // Dismiss `Auth check` message & redirect to `/`
    toast.update('authCheck', {
      render: <span> <span role="img" aria-label="img"> &nbsp;✅&nbsp; </span> Successfull authorization</span>,
      type: toast.TYPE.SUCCESS,
      autoClose: 1000
    });
    // track user
    // trackRegisteredUser();
    return true;
  }).catch((error) => {
    toastResult = error.response?.status;
    let toastMessage = <span>
      <span role="img" aria-label="img"> &nbsp;⛔️&nbsp; </span>
      {error.response?.data?.userMessage || error.message || 'Internal error (code FJ6J2H)'}
    </span>;
    // Show err 
    toast.update('authCheck', {
      render: toastMessage,
      type: toast.TYPE.ERROR,
      autoClose: 3000
    });
    return false;
  });
}

export const revokeRefreshToken = () => {
  return axios.post(
    window.Config.api.auth.revokeRefreshTokens,
    { refreshToken: getStoredRefershToken() }
  ).then(() => {
    return signOut();
  }).catch(error => {
    console.error(`Error AR88N8 ${error}`);
    return false;
  });

}

export const signOut = () => {
  removeStoredRefreshToken();
  removeStoredAccessToken();
  window.location.replace('/logout');
  return true;
}

// Get User IP and call callback providing IP
export const getIpAndCall = (callback) => {
  var clientIP = '0.0.0.0'
  axios(window.Config.URLs.getip, { timeout: 1000 })
    .then(resp => clientIP = resp.data)
    .catch(err => {
      console.warn('Failed to identify client ip');
      console.warn(err)
    })
    .finally(() => callback(clientIP));
}
