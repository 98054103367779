import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import GoogleAdsHeader from '../../components/GoogleAdsHeader/GoogleAdsHeader';
import { authorized, checkAuthorization, getIpAndCall, getStoredRefershToken, storeRefershToken } from '../../utils/authUtils';
import LoginPage from '../LoginPage';
import LogoutPage from '../LogoutPage';
import NotFoundPage from '../NotFoundPage';
import ProfilePage from '../Profile/ProfilePage';

const App = () => {

  const LOGIN_PAGE_HREF = '/login';
  const LOGOUT_PAGE_HREF = '/logout';

  const params = new URLSearchParams(window.location.search);
  const codeFromEmail = params.get('code');
  const apiKeyParam = params.get('api_key');

  if (apiKeyParam) {
    storeRefershToken(apiKeyParam);
    authorized();
  }

  // authorize if `code` argument was passed. useEffect for async
  useEffect(() => {
    const authorize = (clientIP) => checkAuthorization(codeFromEmail, clientIP);
    if (codeFromEmail) {
      getIpAndCall(authorize);
    }
  }, [codeFromEmail]);

  return (
    <ErrorBoundary FallbackComponent={ErrFalback}>
      <Router>
        <Switch>
          <Route path={LOGIN_PAGE_HREF} component={LoginPage} />
          <Route path={LOGOUT_PAGE_HREF} component={LogoutPage} />
          <PrivateRoute path="/" Component={ProfilePage} isAuthorized={getStoredRefershToken() || codeFromEmail} fallback={LOGIN_PAGE_HREF} />
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </ErrorBoundary>
  );
};


const PrivateRoute = ({ Component, isAuthorized, fallback }) => {
  return (
    <Route
      render={() =>
        isAuthorized ?
          <>
            <GoogleAdsHeader />
            <Component />
          </>
          :
          <Redirect to={{ pathname: fallback }} />
      }
    />
  );
};

const ErrFalback = ({ error, componentStack, resetErrorBoundary }) => {
  return (
    <div>
      <h1>An error occurred: {error.message}</h1>
      <a href="/" onClick={resetErrorBoundary} >
        <span>
          Try to reload this page
        </span>
      </a>
    </div>
  );
};

export default App;
