import React from 'react';
import './loading.scss'



function Loading (props){
  const color = props.color;
  const w = props.width ?? 30;
  const h = props.height ?? 30;
  return (
    <div style={{width: w,height: h}}  className="loading--circle">
      <svg className="loading--circle__spinner" viewBox="0 0 50 50">
        <circle stroke={color} className="loading--circle__spinner__path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
      </svg>
    </div>
  );
}

export default Loading;
