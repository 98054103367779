import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { signOut, getEmailFromToken, getStoredAccessToken } from '../../utils/authUtils';
import Logo from '../Logo';
import './ProfileHeader.scss';

const ProfileHeader = () => {

  const [isMobileMenu, setMobileMenu] = useState(Math.min(window.outerWidth, window.innerWidth) < 800);
  const [mobileMenuCollapsed, setMobileMenuCollapsed] = useState(true);
  const menuEnabled = true;
  const userEmail = getEmailFromToken(getStoredAccessToken());

  React.useEffect(() => {
    function handleResize() {
      setMobileMenu(Math.min(window.outerWidth, window.innerWidth) < 800);
    }
    window.addEventListener('resize', handleResize)
  });

  const MenuToggleSvg = () => {
    const svgHeight = 30;
    const svgWidth = 30;
    const strokeWidth = 4;
    const spaceWidth = (svgHeight - strokeWidth * 3) / 4;
    const linePadding = 5;
    return (
      mobileMenuCollapsed ?
        <svg width="30px" height="30px" viewBox="0 0 30 30">
          {[0, 1, 2].map((i) => {
            return (
              <line
                key={'line' + i}
                x1={linePadding} y1={strokeWidth * i + spaceWidth * (i + 1)}
                x2={svgWidth - linePadding} y2={strokeWidth * i + spaceWidth * (i + 1)}
                stroke="#eee"
                strokeWidth={strokeWidth}
                strokeLinecap="round">
              </line>
            )
          })}
        </svg>
        :
        <svg width="30px" height="30px" viewBox="0 0 30 30">
          <line x1={linePadding} y1={linePadding}
            x2={svgWidth - linePadding} y2={svgHeight - linePadding}
            stroke="#eee"
            strokeWidth={strokeWidth}
            strokeLinecap="round">
          </line>
          <line x1={svgWidth - linePadding} y1={linePadding}
            x2={linePadding} y2={svgHeight - linePadding}
            stroke="#eee"
            strokeWidth={strokeWidth}
            strokeLinecap="round">
          </line>
        </svg>
    )
  }

  return <div id="headerNav" className="preference-header" >
    <div className="brand-title">
      <Logo />
    </div>
    {
      menuEnabled && (
        isMobileMenu ?
          <div className="header-menu-mobile">
            <Dropdown onToggle={(expanded) => setMobileMenuCollapsed(!expanded)}>
              <Dropdown.Toggle className="menu-toggle">
                <MenuToggleSvg />
              </Dropdown.Toggle>
              <Dropdown.Menu >
                <Dropdown.Item className="header-menu-mobile-item" href="/#api-key">
                  <span role="img" aria-label="key">🔑</span>&nbsp;API key
                </Dropdown.Item>
                {
                  userEmail.includes("uzum.com") &&
                  <Dropdown.Item className="header-menu-mobile-item" href="https://buy.stripe.com/28og1H6gK3JT4W4aEE" target="_blank" rel="noopener noreferrer">
                    <span role="img" aria-label="key">💵</span>&nbsp;Premium subscriptions
                  </Dropdown.Item>
                }
                <Dropdown.Item className="header-menu-mobile-item" href="/#how-to">
                  <span role="img" aria-label="flag">🏁</span>&nbsp;How to use
                </Dropdown.Item>
                {
                  window.Config.showSubscription &&
                  <Dropdown.Item className="header-menu-mobile-item" href="/#subscription">Your subscription</Dropdown.Item>
                }
                {
                  // Disable beta tester form
                  false && !localStorage.getItem('formSentState') &&
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Item className="header-menu-mobile-item" href="/access-for-beta-testers">Beta testers</Dropdown.Item>
                  </>
                }
                <Dropdown.Divider />
                <Dropdown.Item className="header-menu-mobile-item" href={window.Config.URLs.github}>
                  <div className="github-icon" />GitHub
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item className="header-menu-mobile-item" onClick={signOut}>
                  <FontAwesomeIcon icon={faSignOutAlt} color="black" />
                  &nbsp;
                  Sign out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          :
          <div className="header-menu">
            <a className="menu-link" href="/#widget">No-code widget</a>
            <a className="menu-link" href="/#support">Support</a>
            <a className="menu-link" href="/#usage">Usage</a>
            <a className="menu-link" href="/#how-to">How to use</a>
            <a className="menu-link" href="/#api-key">API key</a>
            {
              userEmail.includes("uzum.com") &&
              <a className="menu-link" target="_blank" rel="noopener noreferrer" href="https://buy.stripe.com/28og1H6gK3JT4W4aEE">
                Premium subscriptions
              </a>
            }
            
            
            {
              window.Config.showSubscription &&
              <a className="menu-link" href="/#subscription">Your subscription</a>
            }
            {
              // Disavle beta tester form
              false && !localStorage.getItem('formSentState')
              && <a className="menu-link" href="/access-for-beta-testers">Beta testers</a>
            }
            <a id="btn_nbit3qny4jc" className="btn" href={window.Config.URLs.github} target="_blank" rel="noopener noreferrer">
              <div className="github-icon" />
              <span>GitHub</span>
            </a>
            <a id="btn_ng3kj4hklasd" className="btn primary" href="/#"
              onClick={(e) => { e.preventDefault(); signOut(); }} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faSignOutAlt} color="white" />
              <span>Sign out</span>
            </a>
          </div>
      )
    }
  </div>
}

export default ProfileHeader;
