import { faCheckCircle, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from "react";
import ReactTooltip from 'react-tooltip';
import './Copy2Clipboard.scss';

const Copy2Clipboard = (props) => {

  const [justCopied, setJustCopied] = useState(false);

  const onClickCopyInner = (e) => {
    e.preventDefault();
    setJustCopied(true);
    navigator.clipboard.writeText(props.text2Copy);
    setTimeout(() => setJustCopied(false), 1000);
  }

  return (
    <div className="copy2clipboard">
      <a target="_blank" href="/#"
        data-tip data-for="copy2clipboard"
        className={justCopied ? "copy2clipboard-link--done" : "copy2clipboard-link"}
        onClick={(e) => justCopied ? e.preventDefault() : onClickCopyInner(e)}>
        <FontAwesomeIcon icon={justCopied ? faCheckCircle : faCopy} color="gray" />
      </a>
      <ReactTooltip id="copy2clipboard" place="left" type="dark" effect="solid">
        <span>
          Copy to clipboard
        </span>
      </ReactTooltip>
    </div>
  )
}

export default Copy2Clipboard;
