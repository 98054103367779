import React, { useState, useEffect }  from 'react';
import { callSecuredAPI, getStoredRefershToken } from '../../utils/authUtils';
import './BillingFrame.scss';

const BillingFrame = () => {

    const [showBilling, setShowBilling] = useState(false);
    const [billingURL, setBillingURL] = useState(false);
    const [isPageLoaded, setPageLoaded] = useState(false);
    const enableDebugToggle = true;

    const onFrameLoad = () => {
        // need this ugly W/A because when CB iframe is loaded page "jumps" to it 
        const timer = setTimeout(() => {
            setPageLoaded(true);
        }, 2000);
        return () => clearTimeout(timer);
    }

    useEffect(() => {
        if (!showBilling && enableDebugToggle && getStoredRefershToken()) {
            let redirectUrl = window.location.origin === 
                'https://localhost:3000' ? 'https://profile.upgini.com' : window.location.origin
                + '/logout?noredirect=true';
            callSecuredAPI({
                method: 'POST',
                url: window.Config.api.billing.createBillingSession,
                data: { "redirect_url": redirectUrl }
            }).then(response => {
                if (response.data?.access_url) {
                    setShowBilling(true);
                    setBillingURL(response.data?.access_url); 
                }
            })
        }
    }, [showBilling, billingURL, enableDebugToggle]);
    
    return (
            (showBilling && billingURL && enableDebugToggle) ?
            <iframe src={billingURL} 
                title="billing-frame"
                frameBorder="0" 
                width="100%" 
                height="650px" 
                scrolling="no"
                onLoad={onFrameLoad}
                className={isPageLoaded? "frame-visible": "frame-hidden"}/>
        :
            <div>
                <p className="sub-caption">
                    Sorry, we've encountered a problem trying to get access to subscription management.
                    <br/>
                    Please try to reload this page.
                </p>
            </div>
    );
}

export default BillingFrame;
