import { datadogRum } from '@datadog/browser-rum';
import React from 'react';
import ReactDOM from 'react-dom';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import App from './containers/App';
import reportWebVitals from './reportWebVitals';
import './styles/index.css';

datadogRum.init({
  applicationId: '03abce92-3565-452d-8088-ffaccd204b6d',
  clientToken: 'pub1f5f73a1f98f51bdbfc9b9f0f1f85995',
  site: 'datadoghq.eu',
  service: 'upgini-customer-profile-client',

  // Specify a version number to identify the deployed version of your application in Datadog 
  // version: '1.0.0',
  sampleRate: 100,
  premiumSampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: 'mask-user-input'
});

datadogRum.startSessionReplayRecording();

ReactDOM.render(
  <React.StrictMode>
    <App />
    <ToastContainer
      className="toast-block"
      position="bottom-center"
      autoClose={3000}
      hideProgressBar={false}
      closeOnClick
      pauseOnHover={true}
      pauseOnFocusLoss={false}
    />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
