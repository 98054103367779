import React from 'react';
import cn from 'classnames';
import iconsMap from './iconsMap';

function Icon({ icon, className, ...rest }) {
  const Component = iconsMap[icon];
  const classNames = cn('icon', `icon-${icon}`, className);

  return (
    <Component className={classNames} { ...rest } />
  );
}

export default Icon;
