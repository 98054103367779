import HCaptcha from '@hcaptcha/react-hcaptcha';
import axios from "axios";
import React, { useState } from "react";
import 'react-toastify/dist/ReactToastify.min.css';
import Loading from '../../components/LoadingCircle/Loading';
import Logo from "../../components/Logo";
import { getIpAndCall } from '../../utils/authUtils';
import { showToast } from '../../utils/commons';
import './LoginPage.scss';

axios.defaults.baseURL = window.Config?.api.root;

const LoginPage = () => {

    const [humanVerified, setHumanVerified] = useState(true); //window.location.origin === 'https://localhost:3000')
    const [isSuccessRequest, setSuccessRequest] = useState(false);
    const [submitIsClicked, setSubmitIsClicked] = useState(false);
    const [userEmail, setUserEmail] = useState('');

    const sendMagicLink = (clientIP) => {
        axios.post(window.Config.api.auth.sendLink, {
            email: userEmail,
            adsManagerDomain: window.location.host,
            clientIP: clientIP
        })
            .then((response) => {
                setTimeout(() => setSubmitIsClicked(false), 1000);
                setSuccessRequest(true);
                // trackRegistrationAttempt(userEmail);
            })
            .catch((error) => {
                setTimeout(() => setSubmitIsClicked(false), 1000);
                setSuccessRequest(false);
                let message = error.response?.data?.userMessage || error.message || 'Failed to send magic link (code 75D4YD)';
                showToast('⛔️', message, { type: 'error' });
            })
    }

    const formSubmit = (e) => {
        e.preventDefault();
        setSubmitIsClicked(true);
        // Try to get client ip and send magic link
        getIpAndCall(sendMagicLink);
    }


    const ConditionalRender = () => {
        if (!humanVerified) {
            return (
                <div>
                    <p> Please complete the security check to Sign In </p>
                    <br />
                    <HCaptcha className="main-page-center"
                        sitekey="7df93157-5360-4af0-a81f-25329cf70bef"
                        onVerify={(token, ekey) => setTimeout(() => setHumanVerified(true), 1000)} />
                </div>
            );
        } else if (humanVerified && !isSuccessRequest) {
            return (
                <div className="login-form">
                    <form onSubmit={formSubmit}>
                        <div className="login-form__group">
                            <label htmlFor="login">
                                Enter email and we’ll send you magic link for Sign in
                            </label>
                            <br />
                            <input
                                autoFocus
                                type="email" name="login"
                                required
                                value={userEmail}
                                onChange={(e) => setUserEmail(e.target.value)}>
                            </input>
                        </div>
                        <button data-test="login-button" disabled={submitIsClicked} type="submit">
                            <span>Login</span>
                            {(submitIsClicked) ? <Loading color="#ddd" /> : null}
                        </button>
                    </form>
                </div>
            )
        } else if (humanVerified && isSuccessRequest) {
            return (
                <div className="login-form">
                    <p>
                        Check your email
                        <br />
                        We sent an email to you at {userEmail}.
                        <br />
                        It has a magic link that’ll sign you in.
                    </p>
                </div>
            )
        } else {
            return null
        }
    }

    return (
        <div className="login-page-container">
            <header className="login-header">
                <Logo />
            </header>
            {<ConditionalRender />}
        </div>
    );
}

export default LoginPage;
