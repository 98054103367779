import React from 'react';
import './Logo.scss';
import Icon from '../Icon';

const Logo = () => {

  const isLoginPage = window.location.pathname === '/login';

  return (
    <div className="logo" data-test="logo">
      <a href={window.Config.URLs.landing} className="logo__link">
        <Icon icon="logo" />
      </a>
      { isLoginPage && <p>Data Search Engine</p> }
    </div>
  )
}

export default Logo;
