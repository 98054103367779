
import React from 'react';

const Close2 = () => <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
<path d="M13.3 0.709712C13.1131 0.52246 12.8595 0.417227 12.595 0.417227C12.3305 0.417227 12.0768 0.52246 11.89 0.709712L6.99997 5.58971L2.10997 0.699712C1.92314 0.512459 1.66949 0.407227 1.40497 0.407227C1.14045 0.407227 0.886802 0.512459 0.699971 0.699712C0.309971 1.08971 0.309971 1.71971 0.699971 2.10971L5.58997 6.99971L0.699971 11.8897C0.309971 12.2797 0.309971 12.9097 0.699971 13.2997C1.08997 13.6897 1.71997 13.6897 2.10997 13.2997L6.99997 8.40971L11.89 13.2997C12.28 13.6897 12.91 13.6897 13.3 13.2997C13.69 12.9097 13.69 12.2797 13.3 11.8897L8.40997 6.99971L13.3 2.10971C13.68 1.72971 13.68 1.08971 13.3 0.709712Z" />
</svg>

export default Close2;


