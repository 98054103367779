import React from "react";
import './NotFoundPage.scss';

const NotFoundPage = () => {


    return (
        <div className="not-found-page-container">
            <p>404<br />Page not found</p>
        </div>
    );

}

export default NotFoundPage;
