import React from 'react';
import './Footer.scss';

const Footer = () => {

  const BASE_URL = "https://upgini.com"

  const links = {
    gitHub: "https://github.com/upgini/upgini",
    quickStart: BASE_URL + "/#quick_start",
    dataSources: BASE_URL + "/#data_sources",
    gtiHubReadme: "https://github.com/upgini/upgini#readme",
    termsOfService: BASE_URL + "/terms-of-service-for-users",
    privacyPolicy: BASE_URL + "/privacy-policy"
  }

  return (
    <div className="footer">
      <div className="footer__primary">
        <span>© 2022-2023 upgini</span>
        <nav className="footer__linkRow"></nav>
      </div>
      <div className="footer__linkGroups">
        <ul className="footer__linkGroup">
          <li className="weight-text">Product</li>
          <a href={links.gitHub} target="_blank" rel="noopener noreferrer" >GitHub</a>
          <a href={links.quickStart}>Quick start</a>
          <a href={links.dataSources}>Data Sources</a>
          <a href={links.gtiHubReadme}>Documentation</a>
        </ul>
        <ul className="footer__linkGroup">
          <li className="weight-text">Legal</li>
          <a href={links.termsOfService}>Terms of Service</a>
          <a href={links.privacyPolicy}>Privacy Policy</a>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
