import React from "react";
import {Helmet} from "react-helmet";

const GoogleAdsHeader = () => {

    // Event snippet for Website lead conversion page 

    return (
        <Helmet
            script={[{ 
                type: 'text/javascript', 
                innerHTML: "gtag('event', 'conversion', {'send_to': 'AW-10820894285/zUAwCNmfq4gDEM385qco'});" 
            }]} 
        />
    );

}

export default GoogleAdsHeader;