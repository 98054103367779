import AttachFile from './icons/AttachFile';
import Close from './icons/Close';
import Close2 from './icons/Close2';
import Search from './icons/Search';
import Logo from './icons/Logo';
import Key from './icons/Key';
import Copy from './icons/Copy';
import Tune from './icons/Tune';
import Loader from './icons/Loader';
import Warning from './icons/Warning';
import Checkbox from './icons/Checkbox';
import IndicatorSelect from './icons/IndicatorSelect';
import Sort from './icons/Sort';
import Bullseye from './icons/Bullseye';
import Dollar from './icons/Dollar';
import TrendingUp from './icons/TrendingUp';
import Plus from './icons/Plus';
import PhoneIcon from './icons/PhoneIcon';

const iconsMap = {
  attachFile: AttachFile,
  close: Close,
  close2: Close2,
  search: Search,
  logo: Logo,
  key: Key,
  copy: Copy,
  tune: Tune,
  loader: Loader,
  warning: Warning,
  checkbox: Checkbox,
  indicatorSelect: IndicatorSelect,
  sort: Sort,
  bullseye: Bullseye,
  dollar: Dollar,
  trendingUp: TrendingUp,
  plus: Plus,
  phoneIcon: PhoneIcon,
}

export default iconsMap